.home.page{
	h2.banner-title {
		font-size: 64px;
		color: #fff;
		line-height: 65px;
		margin-top: 50px;
	}

	p.banner-body {
		font-size: 18px;
		color: #fff;
	}

	p.cta-wrapper {
		margin: 40px 0px 60px 0px;
	}

	a.banner-cta {
		background: #fff;
		padding: 10px 40px;
		font-weight: bold;
		color: #007a78;
		border-radius: 20px;
		box-shadow: 1px 2px 5px #888888;

	}

	a.banner-cta:hover {
		background: #e5e5e5;
	}
	// .carousel-inner{
		
	// 	.carousel-item{
	// 		background-size: cover !important; 
	// 		width: 100%;
	// 		height: 609px;
	// 	}
	// }
	#about-us{
		
		h2{
			line-height: 0.1em;
			margin: 10px 0 20px;
			border-top: 1px solid #6c6d70;
			font-size: 22px;

			span{
				background:#fff; 
				padding:0 10px; 
				position: relative;
				top:-5px;
			} 
		}
		p{
			font-size: 16px;
			color: #414042;
			margin-top: 0.5rem;
		}

	}
}


.page.sidebar-primary{
	#main{
		.container {
			.col-md-12{
				p{
					margin-top: 0.5rem;
					margin-bottom: 0.5rem;
					@include font($first, $size:16px,  $color:#555);
				}
				ul{
					padding-left: 20px;
					li{
						@include font($first, $size:16px,  $color:#555);
					}
				}
				h3{
					font-size: 18px;
					line-height: 20px;

				}
				a,a:link{
					color: #0066b1;
					text-decoration: underline;
				}
			}

		}
	}
}

.wp-block-separator{
	border:1px solid #fff;
}

.contact-us{
	.page-header{
		h1{
			@include font($first, $size:24px,  $color:#555);	
		} 
	}
	#main .container .col-md-12 p{
		line-height:20px;
	}
	h2{
		@include font($first, $size:20px,  $color:#555);
		padding-top: 30px;	
	} 
	.gform_title{
		padding-top: 0;
	}

	.gform_wrapper.gravity-theme .gfield_label{
		font-size: 13px;
		text-align: right;
		padding: 0 10px;
	}
	#gform_wrapper_1{
		background-color: #f5f5f5;
		border: 1px solid #e3e3e3;

		padding: 9px 15px;
		border-top: 1px solid #e5e5e5;
	}
	.gform_body{
		margin: 30px 0 0;
	}
	.gform_wrapper .gform_validation_errors>h2.hide_summary{
		margin-top: 0;
		padding: 0;
	}
	.gform_wrapper .gform_validation_errors{
		margin: 20px 0;
	}
	.gform_wrapper.gravity-theme .ginput_complex label, .gform_wrapper.gravity-theme .ginput_complex legend{
		display: none;
	}
	.gform_wrapper.gravity-theme .ginput_complex input, 
	.gform_wrapper.gravity-theme .ginput_complex select,
	.gform_wrapper.gravity-theme .gfield input.large, 
	.gform_wrapper.gravity-theme .gfield select.large,
	.gform_wrapper.gravity-theme .gfield textarea{
		width: 35%;
	}
	.gform_wrapper.gravity-theme .gfield textarea.large{
		height: 150px;
	}
	.gform_wrapper.gravity-theme .ginput_complex input{
		padding: 1px 2px;
	}
	.gform_wrapper.gravity-theme .gform_fields.left_label legend.gfield_label{
		text-align: right;
	}
	#gform_submit_button_1{
		color: #fff;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
	background-color: #006dcc;
	background-image: -moz-linear-gradient(top,#08c,#0044cc);
	background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#0044cc));
	background-image: -webkit-linear-gradient(top,#08c,#0044cc);
	background-image: -o-linear-gradient(top,#08c,#0044cc);
	background-image: linear-gradient(to bottom,#08c,#0044cc);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0043cc', GradientType=0);
	border-color: #0044cc #0044cc #002a80;
	*background-color: #0044cc;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
	}
	.gform_wrapper.gravity-theme .gfield_description{
		display: none;
	}
}