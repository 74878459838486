@font-face {
	font-family:'Helvetica-Neue';
	font-display: swap;
	src: url('../fonts/Helvetica Neue Medium Extended.eot');
	src: url('../fonts/Helvetica Neue Medium Extended.eot?#iefix') format('embedded-opentype'),
	url('../fonts/AvenirLTStd-Medium.otf') format('otf'),
	url('../fonts/AvenirLTStd-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
}
// @font-face {
// 	font-family:'AvenirLTStd-Heavy';
// 	font-display: swap;
// 	src: url('../fonts/AvenirLTStd-Heavy.eot');
// 	src: url('../fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/AvenirLTStd-Heavy.woff') format('woff'),
// 	url('../fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
// 	url('../fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// 	font-stretch: normal;
// }

$font-url--google        : 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap';

$first: 'Helvetica-Neue'; // 400
$second:'Open Sans', sans-serif; // 600
//light - 300
// $third: 'OpenSansBold'; // 700
// $four: 'OpenSansExtraBold'; //800

$size : 18px;

@mixin font($family: $first, $weight: 400, $style: normal, $size: $size, $color: $white) {
	font-family: $family;
	font-weight: $weight;
	font-style: $style;
	font-size: $size;
	color: $color;
}

.container{
	max-width: 1110px;
}

a:focus{
	outline:0;
}