header.banner{
	background-color:transparent;
	position: relative;
	z-index: 1;

	.brand{
		img{
			height: 77px;
			margin: 5px 0;
		}
	}

	.container{
		max-width: 960px;
	}
	.fixed {
    position:fixed;
    top:0;
    width: 100%;
}

	#menu-top-menu,
	#menu-main-menu{
		float: right;
		li{
			/*margin: 10px;*/
			outline:0;
			a{
				@include font($first, $size:14px,  $color:#fff);
				padding: 6px 12px 6px 12px;
				&:hover,
				&:focus{
					color: #414042;
					transition: all .3s;
					background-color: #eee;
				}
			}
			// &:last-child{
			// 	a{
			// 		padding-right: 0;
			// 	}
			// }	
		}
		.menu-item-has-children.dropdown.show{
			background: #82c4e6;
			-webkit-animation-delay: 1s;
			animation-delay: 1s;

			animation: fadein 1.4s;
			-moz-animation: fadein 1.4s; /* Firefox */
			-webkit-animation: fadein 1.4s; /* Safari and Chrome */
			-o-animation: fadein 1.4s; /* Opera */

		}
		.current-menu-item{
			border-bottom: 3px solid #572220;
			position: relative;
			a{
				&:after{
					background: #572220;
					bottom: -2.5px;
					content: '';
					left: 50%;
					display: block;
					height: 5px;
					margin: 0 0 0 -2.5px;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					position: absolute;
					width: 5px;
				}
			}
		}


		.show > .dropdown-menu {
			background: url(../images/separator-dropdown.png) no-repeat #82c4e6 50% 0;
			margin: 0;
			max-width: 100%;
			min-width: 100%;
			border-radius: 0;
			border:none;

			margin-top: -5px;

			li{
				border-right: none;
				margin: 0;
				a{

					white-space: normal;
					word-break: break-word;
					@include font($first, $size:14px,  $color:#000000);
					font-weight: 400 !important;
					text-align: center;
					padding: 10px;
					text-transform: capitalize;
					&:hover,
					&:focus{
						background: transparent;
						color: #000;
					}
				}
			}
		}
		
// begin fadein animation dropdown
@keyframes fadein {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-moz-keyframes fadein { /* Firefox */
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-o-keyframes fadein { /* Opera */
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
// end fadein animation dropdown



}

#menu-main-menu{
	li{
		outline:0;
		a{
			color: #414042;
			padding: 33px 12px 33px;
			&:hover,
			&:focus{
				color: #414042;
				background: #eee;
			}
		}
		&:hover,
		&:focus{
			border-bottom: 3px solid #572220;
			background-color: #eee;
		}
	}
	.pay-now,
	.payment-help{
		a{
			background: #b41f23;
			border-radius: 12px;
			color: #fff;
			font-weight: bold;
			padding: 6px 12px 5px 12px;
			margin: 28px 0 28px 15px;
			&:hover,
			&:focus{
				background: #b41f23;
				color: #fff;
			}
		}
		&:hover,
		&:focus{
			background-color: #fff;
			border-bottom: none;
		}
	}
	.payment-help{
		a{
			background: #5C5C5C;
			margin-left: 15px;

			&:hover,
			&:focus{
				background: #5C5C5C;
				color: #fff;
			}
		}
	}
}


#top{
	background: #601013; 
	background: -moz-linear-gradient(top, #601013 0%, #cb2227 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #601013 0%,#cb2227 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #601013 0%,#cb2227 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#601013', endColorstr='#cb2227',GradientType=0 ); /* IE6-9 */
	border-bottom: 1px solid #414f5a;
	padding: 7px 0;
}
#main-header{

	background:#fff;
	-webkit-box-shadow: 0 3px 3px -3px rgba(0,0,0,0.2);
	box-shadow: 0 3px 3px -3px rgba(0,0,0,0.2);
	height: 89px;
	transition: 2s all ease;
}

}

/*begin sticky menu*/
#navbar_top .brand{
	padding-top: 5px;
}
#navbar_top.banner{
	.nav{
		margin-bottom: 0;
		li{
			a{
				padding: 30px 25px 10px;
				color: #000;
				&:hover,
				&:focus{
					color: #000;
				}
			}
		}
	}
}
#navbar_top.banner.sticky-top.d-active{
	position: sticky;
	display: block;

	animation: fadein 1s;
	-moz-animation: fadein 1s; /* Firefox */
	-webkit-animation: fadein 1s; /* Safari and Chrome */
	-o-animation: fadein 1s; /* Opera */
}
#navbar_top.banner.sticky-top{
	display: none;
	background: #e0f0f8;
	z-index: 100;
	padding: 10px 0;
}
/*end sticky menu*/

.collapse:not(.show){
	display: block;
}
.navbar-toggler{
	display: none;
}