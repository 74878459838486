// (max-width: 575.98px)
@include media-breakpoint-only(xs) {

  .collapse:not(.show){
    display: none;
  }
  .navbar-toggler{
    display: block;
    background-color: #572220;
    color: #fff;
  }

  header.banner{
    #main-header{
      height: auto;
      position: static;
      .col-md-2.col-12,
      .col-md-10.col-12{
        text-align:center;
      }
      .col-md-10.col-12{
        padding: 0;
      }
      .navbar-toggler{
        display: inline-block;
        margin: 10px 0 30px;
        outline:0;
        .img-fluid{
          padding: 10px 0;
        }
      }
      #menu-main-menu{
        float: none;
        display: block;
        text-align:left;
        li{
          a{
            padding: 10px 25px;
          }
        }
        .pay-now a, 
        .payment-help a{
          margin: 0 0 8px 0;
        }
      }
    }
  }
  header.banner .brand img{
    margin: 25px 0 10px;
  }
  #top-menu{
    #menu-top-menu{
      display: none;
    }
  }
  #top-menu.collapse.show{
   #menu-top-menu{
    display: block;
  }
}

#no-bg{
  background: transparent;
  display: inline-block;
  padding: 0;
  border:none;
  position: relative;
  top: -5px;
  outline:0;
}

#menu-top-menu{ 
  float: none !important;
  padding: 0 10px;
  display: block;
  text-align:left;
  li{
    a{
      padding: 10px 25px;
    }
  }
}


.home.page{
  #about-us h2{
    line-height:inherit;
    margin: 0;
    span{
      top:-20px;
    }
  }
  }

   footer #top .contact-details h2, 
   footer #top .menu-second-column h2{
    padding: 5px 0;
    margin-top: 30px;
   }

   .contact-us .gform_wrapper.gravity-theme .gform_fields.left_label legend.gfield_label,
   .contact-us .gform_wrapper.gravity-theme .gfield_label{
    text-align:left;
   }
   .contact-us .gform_wrapper.gravity-theme .gfield input.large, .contact-us .gform_wrapper.gravity-theme .gfield select.large, .contact-us .gform_wrapper.gravity-theme .gfield textarea, .contact-us .gform_wrapper.gravity-theme .ginput_complex input, .contact-us .gform_wrapper.gravity-theme .ginput_complex select{
    width: 100%;
   }
   .gform_wrapper.gravity-theme .gform_footer.left_label, .gform_wrapper.gravity-theme .gform_footer.right_label, .gform_wrapper.gravity-theme .gform_page_footer.left_label, .gform_wrapper.gravity-theme .gform_page_footer.right_label{
    padding: 10px 0;
   }
   .contact-us #gform_submit_button_1{
    width: 100%;
   }

}


// (max-width: 767.98px) and (min-width: 576px)
@include media-breakpoint-only(sm) {

.collapse:not(.show){
    display: none;
  }
  .navbar-toggler{
    display: block;
    background-color: #572220;
    color: #fff;
  }

  header.banner{
    #main-header{
      height: auto;
      position: static;
      .col-md-2.col-12,
      .col-md-10.col-12{
        text-align:center;
      }
      .col-md-10.col-12{
        padding: 0;
      }
      .navbar-toggler{
        display: inline-block;
        margin: 10px 0 10px;
        outline:0;
        .img-fluid{
          padding: 10px 0;
        }
      }
      #menu-main-menu{
        float: none;
        display: block;
        text-align:left;
        li{
          a{
            padding: 10px 25px;
          }
        }
        .pay-now a, 
        .payment-help a{
          margin: 0 0 8px 0;
        }
      }
    }
  }
  header.banner .brand img{
    margin: 25px 0 10px;
  }
  #top-menu{
    #menu-top-menu{
      display: none;
    }
  }
  #top-menu.collapse.show{
   #menu-top-menu{
    display: block;
  }
}

#no-bg{
  background: transparent;
  display: inline-block;
  padding: 0;
  border:none;
  position: relative;
  top: -5px;
  outline:0;
}

#menu-top-menu{ 
  float: none !important;
  padding: 0 10px;
  display: block;
  text-align:left;
  li{
    a{
      padding: 10px 25px;
    }
  }
}

}

// (max-width: 991.98px) and (min-width: 768px)
@include media-breakpoint-only(md) {
header.banner #menu-main-menu li a{
  padding: 33px 9px;
}
 header.banner #menu-top-menu{
  justify-content: center;
  float: none;
 }
}

// (max-width: 1199.98px) and (min-width: 992px)
@include media-breakpoint-only(lg) {



}

// (min-width: 1200px)
// @include media-breakpoint-only(xl) { 

  @media (min-width: 1200px) and (max-width: 1399.98px) {

  }


  @media (min-width: 1400px) and (max-width: 1799.98px) {

  }