footer{


	#top{
		background: #5C5C5C;
		padding: 30px 0;
		h3{
			@include font($second, $size:19px,  $color:#E4E4E4);
			text-transform: uppercase;	
				margin:0 0 12px;	
		}
		.general-info{
			text-align:center;
			p{
				@include font($first, $size:13px,  $color:#E4E4E4);
			}
		}
		.contact-details{
			h2{
				@include font($second, $size:16px,  $color:#E4E4E4);
				font-weight: bold;	
				margin:0 0 12px;
			}
			img{
				padding-right: 12px;
				margin-top: -9px;
			}
			.info-label {

				line-height: 23px;
				@include font($first, $size:13px,  $color:#E4E4E4);
				font-weight: bold;		

			}
			p{
				@include font($first, $size:13px,  $color:#E4E4E4);
				margin-bottom: 5px;
			}
			a{
			@include font($first, $size:12px,  $color:#E4E4E4);	
			text-decoration: underline;
			&:hover,
			&:focus{
				text-decoration: none;
			}
			}

			> div{
				display: flex;
				flex-direction: row;
				align-items: baseline;
				> div:first-child {
					width: 33%;
					flex: 0 0 auto;
				}
			}
			
		}
		.menu-second-column{
			h2{
				@include font($second, $size:16px,  $color:#E4E4E4);
				font-weight: bold;	
				margin:0 0 12px;
			}
			#menu-footer-menu{

			}
		}
		#menu-footer-menu{
			    column-count: 2;
			    display: block;
			a{
				@include font($first, $size:12px,  $color:#E4E4E4);	
				text-decoration: underline;
				&:hover,
				&:focus{
					text-decoration: none;
				}
			}	
		}
	} 



	#bottom{
		
		padding: 20px 0;
		background: linear-gradient(to bottom, #601013 0%,#cb2227 100%);
		.nav {
			@include font($first, $size:14px,  $color:#bbb);
			li{
				margin: 0 12px;
			}
		}
	}
	#menu-footer{
		.menu-item{
			padding: 6px 0px;
			a{
				@include font($first, $size:18px,  $color:#fff);
				text-transform: capitalize;
				font-weight: 600;

				&:last-child{
					padding-bottom: 0;
				}
				&:hover,
				&:focus{
					text-decoration: none;
					color: #dff0f8;
					transition: all .3s;
				}
			}
		}
	}
	.social{
		margin-top: 10px;
		.nav-item{
			.nav-link{
				padding: 10px 7px;
				-webkit-transition: all .6s ease; 
				-moz-transition: all .6s ease; 
				-ms-transition: all .6s ease; 
				-o-transition: all .6s ease; 
				transition: all .6s ease;
				&:hover,
				&:focus{
					-moz-transform: rotate(360deg); 
					-webkit-transform: rotate(360deg); 
					-o-transform: rotate(360deg); 
					-ms-transform: rotate(360deg); 
					transform: rotate(360deg);
				}
			}
		}

		.nav-item:first-child{
			.nav-link{
				padding-left: 0;
			}
		}


	}

	#copyright{
		@include font($first, $size:16px,  $color:#fff);
		font-weight: 400;
		.top-bar{
			margin: 56px auto;
		}
	}

	.justify-content-center{
		margin-bottom: 25px;
		li{
			a{
				@include font($first, $size:16px,  $color:#fff);
				font-weight: 600;
				padding: 0 10px;
				&:hover,
				&:focus{
					text-decoration: none;
					color: #dff0f8;
					transition: all .3s;
				}
			}
		}
	}
}